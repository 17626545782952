import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";

import Layout from "../../components/articles/Layout";
import CodePreview from "../../components/CodePreview";
import timeSince from "../../timeSince";

import XMarkIcon from "../../images/inlinesvgs/icon-xmark.svg";
import CheckMarkIcon from "../../images/inlinesvgs/icon-checkmark.svg";

import switchingLogoImage from "../../images/blog/dark-mode-in-html-email/switching-logo.svg";
import switchingLogoBulletproofImage from "../../images/blog/dark-mode-in-html-email/switching-logo-bulletproof.svg";
import openGraphImage from "../../images/blog/dark-mode-in-html-email/sm-blog-darkmode-og.jpg";
import twitterCardImage from "../../images/blog/dark-mode-in-html-email/sm-blog-darkmode-tc.jpg";

const Page = ({ data }) => {
	const title = `How to make dark mode HTML email: everything you need to know.`;
	const description = `The new big trend in tech, dark mode, is coming to email. Learn how it works and how to make dark‑mode‑friendly HTML emails yourself.`;
	const publishedDate = new Date("2019-09-30T06:12:03.490Z");
	const modifiedDate = new Date("2020-03-22T12:18:06.267Z");

	return (
		<Layout
			pageMeta={{
				title: title,
				description: description,
			}}>
			<Helmet>
				<meta property="og:type" content="article" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta
					property="og:image"
					content={data.site.siteMetadata.siteUrl + openGraphImage}
				/>

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content="@pkrupar" />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta
					name="twitter:image"
					content={data.site.siteMetadata.siteUrl + twitterCardImage}
				/>

				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "Article",
						author: {
							"@type": "Person",
							name: "Patrik Krupar",
						},
						publisher: {
							"@type": "Organization",
							name: "Sidemail",
							logo: {
								"@type": "ImageObject",
								url:
									data.site.siteMetadata.siteUrl +
									"/assets/sidemail-logo-lightbg-325x60.png",
							},
						},
						headline: title,
						description: description,
						image: openGraphImage,
						datePublished: publishedDate.toISOString(),
						dateModified: modifiedDate.toISOString(),
					})}
				</script>
			</Helmet>

			<main id="dark-mode-in-html-email" className="blog">
				<div className="container">
					<header>
						<div>
							<Img fluid={data.iphoneInOneHandImage.childImageSharp.fluid} />
						</div>

						<div>
							<h1>Dark mode in&nbsp;HTML email</h1>

							<p className="subtitle">
								The new big trend in tech, dark mode, is coming to email. Learn
								how it works and how to make dark‑mode‑friendly HTML emails
								yourself.
							</p>

							<div className="postMeta">
								<span className="author">
									– by <a href="https://twitter.com/pkrupar">Patrik Krupar</a>
								</span>
								last update on{" "}
								<time
									dateTime={modifiedDate.toISOString()}
									className="postMeta-date">
									{modifiedDate.toLocaleDateString()}
								</time>{" "}
								<span className="postMeta-timeAgo">
									({timeSince(modifiedDate)}&nbsp;ago)
								</span>
							</div>
						</div>
					</header>
				</div>

				<div className="container container--focused">
					<p>
						With the new iOS 13 update, Apple Mail is getting a dark theme and
						becomes the first major email client that supports
						`prefers-color-scheme` CSS media query – this means you can now
						design emails specifically for both dark and light themes.
					</p>

					<p>
						I'm a massive dark mode fan, and blindingly-bright email is my
						nemesis so when I learned about the dark mode in iOS 13, I did the
						only obvious thing and ordered a brand new iPhone to test things
						out.
					</p>

					<p>
						While I was at it, I also tested how dark mode works in almost all
						email clients, including the trouble maker Outlook, and here's what
						I found.
					</p>

					<div className="annotation">
						<div className="annotation-title">
							What is prefers-color-scheme?
						</div>
						The{" "}
						<a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme">
							prefers-color-scheme CSS media query
						</a>{" "}
						is used to detect whether the user prefers a light or dark theme,
						making it possible to design email specifically for both. With the
						iOS 13 update,{" "}
						<b>
							the support in most popular email clients jumped from 2.3% to
							38.4%!
						</b>{" "}
						A huge step thanks to Apple Mail's popularity. Surprisingly, Outlook
						was the only email client that supported this before Apple Mail.
					</div>
				</div>

				<section className="textWithTableSection">
					<div className="container container--focused">
						<div className="videoAndTitle">
							<video controls loop preload="auto" height={300}>
								<source
									src="/assets/dark-mode-in-html-email/apple-mail-ios-13-dark-mode-email.mp4"
									type="video/mp4"
								/>
							</video>

							<h2>How dark mode works in popular email clients </h2>
						</div>

						<p>
							To render the email message dark itself, email clients invert
							email's colors automatically behind the scenes. For regular
							user-to-user emails, this works well and consistently in all email
							clients.
						</p>

						<p>
							However, it's not that simple for custom HTML emails – those that
							fill most of our inboxes. I'm talking transactional and
							promotional.
						</p>

						<p>
							Here're the differences I found in how email clients handle dark
							mode email rendering:
						</p>
					</div>

					<div className="container">
						<div className="card">
							<table>
								<thead>
									<tr>
										<th style={{ minWidth: 170 }}>Email client </th>
										<th>Popularity </th>
										<th style={{ minWidth: 80 }}>Dark UI </th>
										<th style={{ minWidth: 140 }}>Auto-invert email colors</th>
										<th style={{ minWidth: 190 }}>
											Supports @media (prefers-color-scheme)
										</th>
										<th style={{ minWidth: 200 }} />
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>
											<div className="important">Apple Mail</div>
											<div className="muted">iPhone + iPad</div>
										</td>
										<td>36.1%</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(if transparent background)</div>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/applemail-ios.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Gmail</div>
											<div className="muted">Android 10</div>
										</td>
										<td>
											27.8% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td className="">
											<a href="/assets/dark-mode-in-html-email/gmail-android-1.jpg">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> Before Gmail removed this feature, it was
												the only tested email client that treid to intelligently
												inverse colors of images inside the email (it did pretty
												good job).
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Gmail</div>
											<div className="muted">iOS 13</div>
										</td>
										<td>
											27.8% <span className="muted">*</span>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td className="">
											<a href="/assets/dark-mode-in-html-email/gmail-ios.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> Dark mode is coming, already teased with
												dark splash screen.
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Gmail</div>
											<div className="muted">webmail</div>
										</td>
										<td>
											27.8% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/gmail-webmail.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> Support for dark UI theme, but not the
												actual emails.
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Outlook</div>
											<div className="muted">iOS 13</div>
										</td>
										<td>
											9.1% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/outlook-ios.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Outlook</div>
											<div className="muted">Android 10</div>
										</td>
										<td>
											9.1% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/outlook-android-1.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Outlook</div>
											<div className="muted">Windows 10</div>
										</td>
										<td>
											9.1% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/outlook-windows-10.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Outlook</div>
											<div className="muted">macOS</div>
										</td>
										<td>
											9.1% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/outlook-macos.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> Buggy border color - if border color is
												changed with prefers-color-scheme it still tries to
												invert border color even if it's already dark.
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Apple Mail</div>
											<div className="muted">macOS</div>
										</td>
										<td> 7.5% </td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(if transparent background)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/applemail-macos.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Yahoo!</div>
											<div className="muted">webmail</div>
										</td>
										<td>
											6.3% <span className="muted">*</span>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/yahoo-webmail.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> Support for dark UI theme, but not the
												actual emails.
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">AOL</div>
											<div className="muted">webmail</div>
										</td>
										<td>
											6.3% <span className="muted">*</span>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/aol-webmail.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Outlook.com</div>
											<div className="muted">webmail</div>
										</td>
										<td> 2.3% </td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/outlook-webmail.png">
												Show screenshot
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<div className="important">Windows 10 Mail</div>
											<div className="muted">Windows 10</div>
										</td>
										<td> 0.5% </td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/windows-10-mail.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> The worst email client from the bunch,
												doesn't even support border-radius.
											</div>
										</td>
									</tr>

									<tr>
										<td>
											<div className="important">Zoho Mail</div>
											<div className="muted">webmail</div>
										</td>
										<td> less than 0.5%</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(forced if not already dark)</div>
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/zoho-webmail.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> Inverts color hue, eg.: blue becomes
												orange.
											</div>
										</td>
									</tr>

									<tr>
										<td>
											<div className="important">Mozilla Thunderbird</div>
											<div className="muted">Windows 10</div>
										</td>
										<td> less than 0.5%</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<XMarkIcon className="xIcon" /> No
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/thunderbird-windows-10.png">
												Show screenshot
											</a>
											<div className="note">
												<b>Note:</b> The only email client tested which doesn't
												forcefully auto-invert email colors, but supports
												prefers-color-scheme.
											</div>
										</td>
									</tr>

									<tr>
										<td>
											<div className="important">Spark</div>
											<div className="muted">macOS</div>
										</td>
										<td> less than 0.5%</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(if transparent background)</div>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/spark-macos-dark-mode.png">
												Show screenshot
											</a>
										</td>
									</tr>

									<tr>
										<td>
											<div className="important">Spark</div>
											<div className="muted">iOS 13</div>
										</td>
										<td> less than 0.5%</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(if transparent background)</div>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/spark-ios-dark-mode.png">
												Show screenshot
											</a>
										</td>
									</tr>

									<tr>
										<td>
											<div className="important">Spark</div>
											<div className="muted">Android 9</div>
										</td>
										<td> less than 0.5%</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
											<div className="muted">(if transparent background)</div>
										</td>
										<td>
											<CheckMarkIcon className="checkIcon" /> Yes
										</td>
										<td>
											<a href="/assets/dark-mode-in-html-email/spark-android-dark-mode.png">
												Show screenshot
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div className="container container--focused">
						<p className="u-mb0 u-mt3">
							<i>
								* Popularity is shared across all platforms for the same email
								client because it cannot be reliably distinguished.
							</i>
						</p>

						<p className="u-mb0">
							Popularity source:{" "}
							<a href="https://litmus.com/blog/infographic-the-2019-email-client-market-share">
								Litmus, the 2019 email client market share
							</a>
						</p>
					</div>
				</section>

				<section>
					<div className="container container--focused">
						<h2>How to make HTML emails dark mode friendly</h2>

						<p>
							I already put the data to the use, and a few Outlook related
							challenges later, I made our emails dark mode friendly.{" "}
							<b>Here's how you can do the same:</b>
						</p>

						<div className="annotation">
							<div className="annotation-title">What the data say</div>
							More than 55% of emails might be opened with dark mode enabled.
							Once Gmail joins the dark side, emails that might be opened with
							dark mode enabled will skyrocket to 83%!
						</div>

						<h3>1) Adjusting colors</h3>

						<p>
							Look out for Apple Mail as inverts colors only if the background
							color is transparent or unspecified –{" "}
							<b>white background won't do</b>. The easiest way to make sure
							your emails won't blind anybody is to check whether a background
							color is specified. For more control over the design, this is
							where prefers-color-scheme comes in handy.
						</p>

						<p>
							<b>Syntax (@media prefers-color-scheme):</b>
						</p>

						<CodePreview language="css">
							{`
<style>
	/* Your light mode (default) styles: */
	body {
		background: white;
		color: #393939;
	}

	@media (prefers-color-scheme: dark) {
		/* Your dark mode styles: */

		body {
			background: black;
			color: #ccc;
		}
	}
</style>
`}
						</CodePreview>

						<p>
							<b>A design tip:</b> Avoid pure white (#fff) as the text color. I
							found that contrast ratio around 11.5 for the main text is a nice
							compromise between not too bright a not too dim. Check the
							contrast ratio here:{" "}
							<a href="https://contrast-ratio.com">
								https://contrast-ratio.com
							</a>{" "}
							or use Chrome dev tools.
						</p>

						<img
							src={switchingLogoImage}
							loading="lazy"
							alt="Switching between light and dark logo version in HTML email with prefers-color-scheme media query"
						/>

						<h3>2) Switching between light and dark logo</h3>

						<p>
							A dark text on a dark background is pretty much invisible, and
							that's precisely what happens to a logo if viewed in an email
							client with enabled dark mode.
						</p>

						<p>
							Nowadays, a typical logo usually has a transparent background,
							colorful icon, and dark copy. See the problem? Because email
							clients don't invert image colors, you need to handle it yourself.
						</p>

						<p>To tackle this, you can either:</p>

						<ol className="list">
							<li>
								the easiest way to fix this is to save the logo with a white
								background instead of a transparent background, but I wouldn't
								recommend this approach – dark mode users won't be happy
							</li>
							<li>
								put a light logo on dark background, rest of the email is on
								white background (
								<a href="/assets/dark-mode-in-html-email/litmus-light-logo-on-dark-background.png">
									see how Litmus does it
								</a>
								)
							</li>
							<li>
								make dark mode email your default, a good candidate for this
								would be Spotify as they only offer dark theme in their apps
							</li>
							<li>
								include both light and dark versions of your logo and switch
								between with prefers-color-scheme media query
							</li>
						</ol>

						<p>My favorite is the last approach, here's how you do it:</p>

						<p>
							Simple `display: none` on the dark logo works just fine in all
							modern email clients, but to everyone's surprise, not in Outlook
							and Windows 10 Mail.
						</p>

						<p>In CSS styles:</p>

						<CodePreview language="css">
							{`
<style>
	@media (prefers-color-scheme: dark) {
		.darkLogo {
			display: none !important;
		}

		.lightLogoWrapper,
		.lightLogo {
			display: block !important;
		}
	}
</style>
`}
						</CodePreview>

						<p>…and the HTML structure:</p>

						<CodePreview language="xml">
							{`
<image src="dark-logo.png" class="darkLogo" />

<!--
	To hide the light logo perfectly in Outlook and Windows 10 Mail, 
	you need to wrap the light logo image tag with a div.
-->
<div class="lightLogoWrapper" style="mso-hide: all; display: none">
	<image src="light-logo.png" class="lightLogo" style="display: none" />
</div>
								`}
						</CodePreview>

						<p>
							This approach works pretty well, but it still won't work correctly
							across the board. The dark text on dark background issue will
							happen with email clients that do support dark mode but doesn't
							support prefers-color-scheme. That is Outlook, Windows 10 Mail,
							Zoho, and potentially Gmail.
						</p>

						<img
							src={switchingLogoBulletproofImage}
							loading="lazy"
							alt="Bulletproof method: switching between light and dark logo version in HTML email with prefers-color-scheme media query"
						/>

						<p>
							So, to make the logo in email fully bulletproof, I'll combine the
							method 1) and 4) from above. Method 1) will cover all email
							clients that support dark mode, but not the prefers-color-scheme.
							And method 4) will cover Apple Mail, Outlook on macOS,
							Outlook.com, which do support both.
						</p>

						<p>
							Also, instead of saving the logo on a white background, I'll add
							3-pixel wide background-matching border and save it on a
							transparent background as usual.
						</p>
						<p>
							It's starting look pretty complex (just for a logo), so let's see
							the HTML markup first:
						</p>

						<CodePreview language="xml">
							{`
<!-- Default logo with 3-pixel wide background-matching border -->
<image src="dark-logo-with-background.png" class="darkLogoDefault" />

<!-- Light theme (so dark logo): 
This is for Apple Mail, Outlook on macOS, Outlook.com -->
<div class="darkLogoWrapper" style="mso-hide: all; display: none">
	<image src="dark-logo.png" class="darkLogo" style="display: none" />
</div>

<!-- Dark theme (so light logo): 
This is for Apple Mail, Outlook on macOS, Outlook.com -->
<div class="lightLogoWrapper" style="mso-hide: all; display: none">
	<image src="light-logo.png" class="lightLogo" style="display: none" />
</div>
`}
						</CodePreview>

						<p>…and CSS styles:</p>

						<CodePreview language="xml">
							{`
<style>
	@media (prefers-color-scheme: light) {
		.darkLogoDefault,
		.lightLogo {
			display: none !important;
		}

		.darkLogoWrapper,
		.darkLogo {
			display: block !important;
		}
	}

	@media (prefers-color-scheme: dark) {
		.darkLogoDefault,
		.darkLogo {
			display: none !important;
		}

		.lightLogoWrapper,
		.lightLogo {
			display: block !important;
		}
	}
</style>
								`}
						</CodePreview>
					</div>
				</section>

				<section>
					<div className="container container--focused">
						<h2>Dark mode in Gmail is coming</h2>

						<p>
							<b>UPDATE:</b> Gmail now supports dark mode on Android 10,
							therefore I updated the info in the table above, including
							up-to-date email screenshot. Unfortunately, it doesn't support the{" "}
							<code>@media prefers-color-scheme</code>.
						</p>

						<p>
							The dark mode is coming to Android in the new Android 10, and
							Gmail should go completely dark too, finally. All you need is
							Android 10 and newest Gmail (at least version
							2019.09.01.268168002). However, Google tends to enable new
							features (a dark theme in this case) for users gradually with a
							server-side push, and I haven't got the luck for now.
						</p>
						<p>
							I'm curious to see if support for @media prefers-color-scheme is
							coming to Gmail. From what I read, it doesn't seem promising. I
							guess we have to wait to find out. I'll update the article once I
							get the dark theme in Gmail enabled.
						</p>
					</div>
				</section>

				<section>
					<div className="container container--focused">
						<h2>Wrapping up</h2>

						<p>
							Dark mode is coming to HTML email, and I love it! But, it's
							another thing to worry about, like using HTML tables for layout
							wasn't enough.
						</p>

						<p>
							<a href="https://hosted.sidemail.io/5d919d2fcc34a000fc97cfed">
								Stay up-to-date about the dark mode in email by joining our
								mailing list
							</a>
							. We also share there insights and challenges we face while
							building and growing our SaaS product –{" "}
							<Link to="/">Sidemail</Link>.
						</p>

						<p>Thanks for reading!</p>
					</div>
				</section>

				<section className="subscribeCta lightBlueBg">
					<div className="container container--focused">
						<div className="fancyLabel">About Sidemail</div>

						<h3>Need to deliver emails? 💌</h3>
						<p>
							Sidemail is an all-in-one email platform for SaaS. We'll help you
							with delivering transactional emails via API, sending marketing
							emails, setting up automated email sequences, and managing
							contacts. Simple instructions. Quick setup.
						</p>
						<p>
							Create your account now and start sending your emails in under 30
							minutes.
						</p>

						<a className="button button-primary button-large" href="/">
							Learn more →
						</a>
					</div>
				</section>

				<section className="container container--focused mb-30">
					<h4>More articles:</h4>

					<div className="mb-4">
						<a href="/articles/sendgrid-alternatives/">
							SendGrid Alternatives with Better Customer Support &
							Deliverability
						</a>
					</div>

					<div className="mb-4">
						<a href="/articles/amazon-ses-alternatives/">
							Simple Amazon SES Alternatives for SaaS
						</a>
					</div>

					<div className="mb-4">
						<a href="/articles/mandrill-alternatives/">
							The Best Mandrill Alternatives for Delivering & Managing SaaS
							Emails
						</a>
					</div>

					<div className="mb-4">
						<a href="/articles/mailgun-alternatives/">
							Mailgun Alternatives for SaaS with Better Customer Support &
							Reliability
						</a>
					</div>

					<div className="mb-4">
						<a href="/articles/postmark-alternatives/">
							The Best Postmark Alternatives for Sending SaaS Emails
						</a>
					</div>

					<div className="mb-4">
						<a href="/articles/transactional-email-vs-marketing-email/">
							Transactional Email vs Marketing Email – What’s the Difference +
							Explanation + Examples
						</a>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default Page;

export const query = graphql`
	query {
		iphoneInOneHandImage: file(
			relativePath: {
				eq: "blog/dark-mode-in-html-email/iphone-in-one-hand.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1500) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
